import React, { useState, useEffect, useRef } from "react";
import { EnquiriesHead } from "../../../components";
import { CustomerOrderList, OrderStatusTracking } from "../../../utils/clientMasterAPI";
import underReviewImg from '../../../assets/images/review.svg';
import noRecFoundImg from '../../../assets/images/folder-error.svg';
import listingLoader from '../../../assets/images/listing_loader.gif';
import { useSelector } from 'react-redux';
import { debounce } from "lodash";

const SalesOrderTracking = () => {
    const { user } = useSelector((store) => store.user);
    const [orderList, setOrderList] = useState([]);
    
    const [selectedOrder, setSelectedOrder] = useState(null); // To store the currently selected order
    const [trackingStatus, setTrackingStatus] = useState([]); // To store the tracking status data
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(25);
    const [loading, setLoading] = useState(false);
    const searchOrdersRef = useRef();
    const [totalData, setTotalData] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const pageRef = useRef(0); // Reference to track the current page
    const [orderStatus, setOrderStatus] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState('');

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            if (user?.company_id) {
                if (isInitialLoad || searchQuery.length >= 3 || searchQuery.length === 0) {
                    // console.log(
                    //     isInitialLoad ? "Initial API call triggered" : "Debounced search triggered"
                    // );
                    pageRef.current = 0; // Reset page reference for search
                    setPage(0);
                    setOrderList([]); // Clear any previous list
                    orderListApiCall(!isInitialLoad); // Pass `true` for search, `false` for initial
                    setIsInitialLoad(false);
                }
            }
        }, isInitialLoad ? 0 : 300); // No debounce for the initial load

        return () => clearTimeout(debounceTimeout);
    }, [user, searchQuery, isInitialLoad]);

    const orderListApiCall = async (isSearch = false) => {
        if (isFetching || loading) {
            //console.log("API call blocked: loading or isFetching...");
            return;
        }
    
        // Prevent further calls if all data is loaded
        if (pageRef.current > 0 && orderList.length >= totalData) {
            //console.log("All data already loaded");
            return;
        }
    
        setLoading(true);
        setIsFetching(true); // Block further calls during fetch
      //  console.log("API call - Page:", pageRef.current, "Search:", isSearch ? searchQuery : "");
    
        try {
            const getOrderListData = await CustomerOrderList(
                pageRef.current,
                count,
                user.company_id,
                searchQuery
            );
    
            if (getOrderListData && Array.isArray(getOrderListData[1])) {
                const newOrders = getOrderListData[1];
    
                // Update order list
                setOrderList((prevOrders) =>
                    pageRef.current === 0 ? newOrders : [...prevOrders, ...newOrders]
                );
    
                // Update total data count
                setTotalData(getOrderListData[2] || 0);

                // Handle selection of the first order
                if (pageRef.current === 0) {
                    if (newOrders.length > 0) {
                        setSelectedOrder(newOrders[0]); // Select the first order
                        fetchOrderStatus(newOrders[0].id); // Fetch its status
                    } else {
                        setSelectedOrder(null); // No orders found, clear selection
                        setTrackingStatus([]); // Clear tracking status
                    }
                }   
            } else {
                //console.log("No data returned from API");
                setOrderList([]); // Clear the order list
                setSelectedOrder(null); // Clear selected order
                setTrackingStatus([]); // Clear tracking status
                setTotalData(0); // Reset total data
            }
        } catch (error) {
            //console.error("Error fetching order list:", error);
        } finally {
            setLoading(false);
            setIsFetching(false); // Allow further calls after fetch
        }
    };
    

    const debouncedHandleScroll = debounce(() => {
       // console.log('isFetching '+isFetching)
        //console.log('loading '+loading)
        if (isFetching || loading) return;
        if (
            searchOrdersRef.current.scrollTop + searchOrdersRef.current.clientHeight >=
                searchOrdersRef.current.scrollHeight - 50 && // Trigger 50px before bottom
            orderList.length < totalData && // Ensure more data is available
            !isFetching // Prevent multiple triggers
        ) {
            const nextPage = pageRef.current + 1;
           // console.log("Next page:", nextPage);

            pageRef.current = nextPage; // Update the page reference
            setPage(nextPage); // Trigger state update
        }
    }, 300); // Debounce time in milliseconds

    useEffect(() => {
        const ref = searchOrdersRef.current;
        if (ref) {
            ref.addEventListener("scroll", debouncedHandleScroll);
        }
        return () => {
            if (ref) ref.removeEventListener("scroll", debouncedHandleScroll);
        };
    }, [orderList, totalData, loading, isFetching]);

    useEffect(() => {
        if (page > 0) {
            orderListApiCall();
        }
    }, [page]);

    const fetchOrderStatus = async (orderId) => {
        const statusData = await OrderStatusTracking(orderId, user.company_id); // Assuming this API fetches the status tracking data
        if (statusData[0]) {
            const sortedData = statusData[1].sort((a, b) => a.seq - b.seq);
            setTrackingStatus(sortedData);

        }
        //alert(statusData[0])
        if(statusData[0]) {
            const deliveredStatus = statusData[1].find((status) => status.status === "Dispatched");

            // Check if 'Delivered' status has a date
            const orderStatusText = deliveredStatus?.date ? "Order Closed" : "Order Open";
            
            setOrderStatus(orderStatusText)
            setTrackingStatus(statusData[1]);
            
            //setDownloadUrl(statusData[2] || 'https://icseindia.org/document/sample.pdf')
        }
    };

    const handleOrderClick = (order) => {
        setSelectedOrder(order); // Set the selected order data
        fetchOrderStatus(order.id); // Fetch tracking status for the selected order
    };
    

    // Handle input change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value); // Update search query
    };

    const handleDownload = (url) => {
        // Create an anchor element
        const link = document.createElement('a');
        link.href = url;
        link.download = ''; // Set this if you want a specific filename
        link.target = '_blank'; // Opens in a new tab, optional
        document.body.appendChild(link);
        link.click(); // Programmatically click the link
        document.body.removeChild(link); // Clean up
    };
    return(
        <>
            
            {
                (user) && (!user.company_id) && (
                    <div className="d-flex under_review_block">
                        <img src={underReviewImg} alt="" />
                        <h2>Your account will be under review</h2>
                    </div>
                )
            }
            {
                (user) && (user.company_id) && (<div className="sales_order_tracking">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 order_search_listing">
                            <div className="table_heading_content">
                                <h1 className="sales_left_heading">Sales Order Tracking</h1>
                            </div>  
                            <div className="search_field_block">
                                <h3 className="search_heading">Sales orders</h3>
                                <div className="form-row"> 
                                    <span className="search_svg">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.66634 14.0002C11.1641 14.0002 13.9997 11.1646 13.9997 7.66683C13.9997 4.16903 11.1641 1.3335 7.66634 1.3335C4.16854 1.3335 1.33301 4.16903 1.33301 7.66683C1.33301 11.1646 4.16854 14.0002 7.66634 14.0002Z" stroke="#868594" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M14.6663 14.6668L13.333 13.3335" stroke="#868594" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    </span>
                                    <input className="search_field" placeholder="Search" 
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    />
                                </div>
                                
                                <ul className="search_orders_listing" ref={searchOrdersRef}>
                                {orderList.map((order, index) => (
                                    <li key={`${order.id}-${index}`}>
                                        <a
                                            href="#"
                                            className={`order_search_items ${selectedOrder?.id === order.id ? "active" : ""}`}
                                            onClick={() => handleOrderClick(order)}
                                        >
                                            <span className="order_id_heading">{order.salesOrder}</span>
                                            <span className="order_location">Project: {order.project || "N/A" }</span>
                                            <span className="order_location">Country Location: {order.projectLocation || "N/A"}</span>
                                            {/* <span className="order_purchaser">Purchaser: {order.customer || "N/A"}</span> */}
                                        </a>
                                    </li>
                                ))}
                                {loading && (
                                    <div className="loader_listing">
                                        <img src={listingLoader} alt="Loading..." width={40} />
                                    </div>
                                )}
                                {orderList.length === 0 && !loading && (
                                    <div className="no_rec_found">
                                        <img src={noRecFoundImg} alt="No Records Found" width={40} />
                                        <h5>No Records Found</h5>
                                    </div>
                                )}
</ul>
                            </div>
                        </div>
                        <div className="col-md-8 order_details_col">
                            <div className="track_order_details">
                                <div className="order_tacking_head">
                                <EnquiriesHead heading="Order Details"/>
                                
                                
                                </div>
                                <div className="details_block">
                                
                                <div className="inner_details">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="form-row">
                                                <label>Order Number</label>
                                                <p className="details_data align_btn">

                                                    

                                                    {selectedOrder?.salesOrder ? (
                                                        <>
                                                        {selectedOrder.salesOrder}
                                                        {
                                                        (orderStatus === 'Order Open') && (<span className="order_open">ORDER OPEN</span>)
                                                            }
                                                            {
                                                                (orderStatus === 'Order Closed') && (<span className="order_closed">ORDER Closed</span>)
                                                            }
                                                            
                                                        </>
                                                    ) : (
                                                        '-'
                                                    )}
                                                    </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 ipad_col_50_100">
                                            <div className="form-row">
                                                <label>PUMP DUTY POINT</label>
                                                <p className="details_data">
                                                    {selectedOrder?.head || selectedOrder?.headUOM || selectedOrder?.flow || selectedOrder?.flowUOM
                                                        ? `${selectedOrder?.head ?? ''} ${selectedOrder?.headUOM ?? ''} @ ${selectedOrder?.flow ?? ''} ${selectedOrder?.flowUOM ?? ''}`
                                                        : '-'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 ipad_col_50_100">
                                            <div className="form-row">
                                                <label>COUNTRY LOCATION</label>
                                                <p className="details_data">{selectedOrder?.projectLocation ?? '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-5 max_w_inner_310 ipad_col_50_100">
                                            <div className="form-row">
                                                <label>PROJECT</label>
                                                <p className="details_data">{selectedOrder?.project ?? '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 max_w_inner_275 ipad_col_50_100">
                                            <div className="form-row">
                                                <label>PUMP PURCHASER</label>
                                                <p className="details_data">{selectedOrder?.customer ?? '-'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    
                                </div>
                            </div>
                            {
                            (trackingStatus?.length > 0)  && (
                                <div className="sales_track_status">
                                    <div className="trackStatus_block">
                                        <h3 className="sub_details_head">Tracking Status</h3>
                                        <div className="steps_flow track_flow">
                                            <ul className="progress_bar">
                                            {trackingStatus?.map((status, index) => {
                                            // Check if the current status has a date
                                            const isActive = status.date && status.seq <= Math.max(...trackingStatus.map((s) => s.seq));

                                            return (
                                                

                                                <li
                                                    key={index}
                                                    className={
                                                    status.date && index !== trackingStatus.filter(item => item.date).length - 1
                                                        ? "active"
                                                        : ""
                                                    }
                                                >

                                                <div className="step_data">
                                                    <p className="step_info">{status.status}</p>
                                                    {status.date && <p className="step_date">{status.date}</p>}
                                                    
                                                </div>
                                                <span className="fix_border"></span>
                                                {
                                                    (status.document) && (<button className="transparent_btn download_btn" onClick={() => handleDownload(status.document)}>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6 7.33337V11.3334L7.33333 10" stroke="#ED1D24" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M5.99984 11.3333L4.6665 10" stroke="#ED1D24" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M14.6668 6.66671V10C14.6668 13.3334 13.3335 14.6667 10.0002 14.6667H6.00016C2.66683 14.6667 1.3335 13.3334 1.3335 10V6.00004C1.3335 2.66671 2.66683 1.33337 6.00016 1.33337H9.3335" stroke="#ED1D24" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M14.6668 6.66671H12.0002C10.0002 6.66671 9.3335 6.00004 9.3335 4.00004V1.33337L14.6668 6.66671Z" stroke="#ED1D24" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                    Download
                                                </button> )
                                                    }
                                                </li>
                                            );
                                            })}

                                            
                                            
                                            </ul>
                                        </div>
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
            </div>)
            }
            
        </>
    );
}

export default SalesOrderTracking;