import customFetch, { clientCustomFetch, clientOrderCustomFetch } from "./axios";

export async function CustomerOrderList(page, count, companyCode, searchQuery) {
    let finalResult = [];
    //alert(companyCode)
    try {
        // let requestData = {"typeOfUser":"Customer","customerCode":companyCode,"start":page,"limit":count,"offerNo":searchQuery,"salesOrderNo":"","customerName":""}
        let requestData = {"typeOfUser":"Customer","customerCode":companyCode,"start":page,"limit":count,"offerNo":"","salesOrderNo":searchQuery,"customerName":""}
        const response = await clientOrderCustomFetch.post('/getOrderList.html', requestData);
        const { data, success, message } = response.data;
        //alert(data)
        
        if (success === false) {
            finalResult.push(false)
            finalResult.push(message)
        } else {
            finalResult.push(true)
            finalResult.push(data)
            finalResult.push(response.data.total)
        }
        return finalResult;
    } catch (err) {
        finalResult.push(false)
        finalResult.push('There is something wrong. Please try again')
        return finalResult;
    }
};

export async function OrderStatusTracking(orderId, companyCode) {
    let finalResult = [];
    try {
        const requestData = {
            typeOfUser: "Customer",
            id: orderId,
            customerName:"",
            customerCode: companyCode,
        };

        const response = await clientOrderCustomFetch.post('/getOrderStatusHistory.html', requestData);

        // Validate response structure
        const responseData = response?.data || {};
        const data = responseData.data || [];
        const success = responseData.success || false;
        const message = responseData.message || 'No message provided';

        // Check success and process accordingly
        if (!success) {
            finalResult.push(false);
            finalResult.push(message);
        } else {
            finalResult.push(true);
            finalResult.push(data);
        }

        return finalResult;
    } catch (err) {
        // Handle unexpected errors
        finalResult.push(false);
        finalResult.push('There is something wrong. Please try again');
        return finalResult;
    }
};

export async function CountryPhoneCodeList() {
    let finalResult = [];
    try {
        const response = await customFetch.get('/get-country-phone-code');
        const { status, message, error, result } = response.data;
        //alert(data)
        if (status === false) {
            finalResult.push(false)
            finalResult.push(message)
        } else {
            finalResult.push(true)
            finalResult.push(result)
        }
        return finalResult;
    } catch (err) {
        finalResult.push(false)
        finalResult.push('There is something wrong. Please try again')
        return finalResult;
    }
};

export async function CountryList() {
    let finalResult = [];
    try {
        let requestData = {"appRegion":"naffco"}
        const response = await clientCustomFetch.post('/getCountry.html', requestData);
        // const response = await customFetch.get('/country-list');
        const { data, success, message } = response.data;
        //alert(data)
        if (success === false) {
            finalResult.push(false)
            finalResult.push(message)
        } else {
            finalResult.push(true)
            finalResult.push(data)
        }
        return finalResult;
    } catch (err) {
        finalResult.push(false)
        finalResult.push('There is something wrong. Please try again')
        return finalResult;
    }
};

export async function PumpType() {
    let finalResult = [];
    try {
        const response = await clientCustomFetch.get('/pumpType.html');
        //const response = await customFetch.get('/flowUnitsDummyList');
        const { data, success, message } = response.data;
        //alert(data)
        if (success === false) {
            finalResult.push(false)
            finalResult.push(message)
        } else {
            finalResult.push(true)
            finalResult.push(data)
        }
        return finalResult;
    } catch (err) {
        finalResult.push(false)
        finalResult.push('There is something wrong. Please try again')
        return finalResult;
    }
};

export async function FlowUnits() {
    let finalResult = [];
    try {
        const response = await clientCustomFetch.get('/flowUOM.html');
        //const response = await customFetch.get('/flowUnitsDummyList');
        const { data, success, message } = response.data;
        //alert(data)
        if (success === false) {
            finalResult.push(false)
            finalResult.push(message)
        } else {
            finalResult.push(true)
            finalResult.push(data)
        }
        return finalResult;
    } catch (err) {
        finalResult.push(false)
        finalResult.push('There is something wrong. Please try again')
        return finalResult;
    }
};

export async function HeadUnits() {
    let finalResult = [];
    try {
        const response = await clientCustomFetch.get('/headUOM.html');
        const { data, success, message } = response.data;

        if (success === false) {
            finalResult.push(false)
            finalResult.push(message)
        } else {
            finalResult.push(true)
            finalResult.push(data)
        }
        return finalResult;
    } catch (err) {
        finalResult.push(false)
        finalResult.push('There is something wrong. Please try again')
        return finalResult;
    }
};


export async function Certification() {
    let finalResult = [];
    try {
        const response = await clientCustomFetch.get('/certificationList.html');
        const { data, success, message } = response.data;

        if (success === false) {
            finalResult.push(false)
            finalResult.push(message)
        } else {
            finalResult.push(true)
            finalResult.push(data)
        }
        return finalResult;
    } catch (err) {
        finalResult.push(false)
        finalResult.push('There is something wrong. Please try again')
        return finalResult;
    }
};

export async function DriverType() {
    let finalResult = [];
    try {
        const response = await clientCustomFetch.get('/driverType.html');
        const { data, success, message } = response.data;

        if (success === false) {
            finalResult.push(false)
            finalResult.push(message)
        } else {
            finalResult.push(true)
            finalResult.push(data)
        }
        return finalResult;
    } catch (err) {
        finalResult.push(false)
        finalResult.push('There is something wrong. Please try again')
        return finalResult;
    }
};